import { useMemo } from 'react';

import { PageData, StaticPageId, usePageData } from '../data';

export const useAggregatedStaticPagesConfig = (): Record<
  StaticPageId,
  PageData
> => {
  const pages = usePageData().list;

  const aggregatedPages = useMemo(
    () =>
      (pages ?? []).reduce(
        (acc, item) => ({
          ...acc,
          [item.data.pageId]: { ...item },
        }),
        {},
      ),
    [pages],
  );

  return aggregatedPages as Record<StaticPageId, PageData>;
};
