import { win } from '../fragments';

export const formatDate = (date: string) =>
  win?.Intl
    ? new Intl.DateTimeFormat(navigator.language, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(new Date(date))
    : date;
