import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, PageSeoData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<PageSeoData> => {
  const list: PageSeoData[] = graphqlResult.allContentfulPageSeo.nodes.map(
    (node): PageSeoData => ({
      id: node.contentful_id,
      type: DataType.PageSeo,
      data: rejectNil({
        title: node.title,
        description: node.description,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const usePageSeoData: DataHook<PageSeoData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulPageSeo {
        nodes {
          contentful_id
          title
          description
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
