import { DataModel } from '../model';
import { DataIndex } from '../types';

export const makeRecord = <TData extends { id: string }>(
  list: TData[],
): Record<string, TData> =>
  list.reduce(
    (acc, entry) => ({
      ...acc,
      [entry.id]: entry,
    }),
    {},
  );

export const makeDataIndexFromList = <TDataEntry extends DataModel>(
  list: TDataEntry[],
): DataIndex<TDataEntry> => ({
  fallback: list[0],
  list,
  record: makeRecord(list),
});
